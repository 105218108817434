import React from 'react';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/Login';
import Home from './components/Home';
import './App.css';
import PendingUser from './components/PendingUser';
import UserDetail from './components/UserDetail';
import Chat from './components/Chat';
import ReportedUser from './components/ReportedUser';
import StaffList from './components/StaffList';
import StaffDetail from './components/StaffDetail';
import NewStaffDetail from './components/NewStaff';
import MyProfile from './components/MyProfile';
import AssignedMembers from './components/AssignedMemberList';
// import { initializeFirebase , getFirestoreInstance} from './services/firebase';

const App = () => {
  // initializeFirebase();
  // getFirestoreInstance();
  return (
    <div className="app-container">
    <Router>
      <Routes>
          <Route path="/" Component={Login} />
          <Route path="/home" Component={Home} />
          <Route path="/chat" Component={Chat} />
          <Route path="/pendinguser" Component={PendingUser} />
          <Route path="/userdetail" Component={UserDetail} />
          <Route path="/reportedUsers" Component={ReportedUser} />
          <Route path="/staffList" Component={StaffList} />
          <Route path="/staffdetail" Component={StaffDetail} />
          <Route path="/addNewStaff" Component={NewStaffDetail} />
          <Route path="/myprofile" Component={MyProfile} />
          <Route path="/assignedmembers" Component={AssignedMembers} />
          
        </Routes>
    </Router>
  
      {/* Include the Footer component */}
     
    </div>
  );
};

export default App;
