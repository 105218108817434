export const logMessage = (message, type = 'log') => {
    switch (type) {
      case 'log':
        // console.log(message);
        break;
      case 'warn':
        console.warn(message);
        break;
      case 'error':
        console.error(message);
        break;
      default:
        // console.log(message);
    }
  };