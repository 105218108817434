import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getUserList,getUserListSearch } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import imagePaths from '../imagePaths';
import { logMessage } from '../utils/helpers';


const PendingUser = () => {
  const navigate = useNavigate();
  const limit = 20;
  const isApproved= false;
  const [data, setData] = useState([]);
  const [startAfter, setStartAfter] = useState(null);
  const [endBefore, setEndBefore] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = async (value) => { 
    if(value == ""){
      setSearchQuery(value);
      const { userList, firstVisible:firstDoc, lastVisible: lastDoc } =  await getUserList(limit, null, null, isApproved);
      setData(userList);
      setStartAfter(lastDoc);
      setEndBefore(firstDoc);
      
    }else{
      setSearchQuery(value);
    }
    
  };

  const handleSearchClick = async (e) =>{
    const { userList, firstVisible:firstDoc, lastVisible: lastDoc } = await getUserListSearch( isApproved, searchQuery);
    setData(userList);
    // setStartAfter(lastDoc);
    // setEndBefore(firstDoc);
  }
  
  const handleRowClick = (user_id) => {
    navigate("/userdetail?user_id=" + user_id);
    // Add your logic here for what should happen when the row is clicked
  };
  const handlePreviousPage = async () => {
    // console.log(endBefore);
    // logMessage("prevlcicl");
    // setLoading(true);
    setSearchQuery("");
    const { userList, firstVisible:firstDoc, lastVisible: lastDoc } = await getUserList(limit, null,endBefore,isApproved);
    setData(userList);
    setStartAfter(lastDoc);
    setEndBefore(firstDoc);
    // setLoading(false);
  };

  const handleNextPage = async () => {
    // setLoading(true);
    setSearchQuery("");
    const { userList, firstVisible:firstDoc, lastVisible: lastDoc } = await getUserList(limit, startAfter, null,isApproved);
    setData(userList);
    setStartAfter(lastDoc);
    setEndBefore(firstDoc);
    // setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const { userList,firstVisible:firstDoc, lastVisible: lastDoc } = await getUserList(limit, startAfter, null, isApproved);
        setData(userList);
        setStartAfter(lastDoc);
        setEndBefore(firstDoc); 
        setIsDataFetched(true);
        // setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }finally {
        // console.log();
        setLoading(false);
      }
    };
    if (!isDataFetched) {
      fetchData();
    }
  }, []);
  // const data = [
  //   { id: 1, name: 'John Doe', profession: 'Software Engineer',profession: 'Software Engineer' },
  //   { id: 2, name: 'Jane Smith', profession: 'Data Scientist',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   // Add more demo data as needed
  // ];
  return (
    <div className="inside-page-container">
      <Navbar />
      <Sidebar />
      <div className='body-page-container'>
      {/* {loading && (
        <div className="loader-container">
         <Bars color="#36454F"  loading={loading} height="40" width="40"/>
        </div>
      )} */}
        <div className="body-content">
          <div className="admin-header">
            <h4>Pending Users</h4>
          </div>
          {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
          <div className="body-content-inside">
          <div style={{marginBottom:'10px', display:'flex', alignItems:'center'}}className="searchBoxContainer">
              <input type="text" 
              placeholder='search'
              style={{width:'300px', padding:'4px'}}
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
              ></input>
              <span style={{ marginLeft: '3px', textAlign: 'center', cursor:'pointer' }} onClick={handleSearchClick}><img style={{width:'16px'}} src="./images/search.png"></img> </span >
            </div>
            <TableContainer component={Paper} style={{ marginTop: '0px', border: 'solid 1px #0303' }}>
              <Table>
                <TableHead className='head_table'>
                  <TableRow>
                    <TableCell style={{ padding: '5px' }}>Sr No</TableCell>
                    <TableCell style={{ padding: '5px' }}>UID</TableCell>
                    <TableCell style={{ padding: '5px' }}>Profile Image</TableCell>
                    <TableCell style={{ padding: '5px' }}>Email</TableCell>
                    <TableCell style={{ padding: '5px' }}>Name</TableCell>
                    <TableCell style={{ padding: '5px' }}>Phone Number</TableCell>
                    <TableCell style={{ padding: '5px' }}>Country</TableCell>
                    <TableCell style={{ padding: '5px' }}>Gender</TableCell>
                    <TableCell style={{ padding: '5px' }}>Age</TableCell>
                    {/* <TableCell style={{ padding: '5px' }}>Religion</TableCell> */}
                    {/* <TableCell style={{ padding: '5px' }}>Chat</TableCell> */}
                    {/* <TableCell style={{ padding: '5px' }}>License Type</TableCell> */}
                    {/* <TableCell style={{ padding: '5px' }}>City/Country</TableCell>
                <TableCell style={{ padding: '5px' }}>Status</TableCell>
                <TableCell style={{ padding: '5px' }}>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}></TableCell>
                    </TableRow>
                  ) : (
                  data.map((row) => (
                    
                    <TableRow key={row.user_id} className="hover-row" onClick={() => handleRowClick(row.user_id)}>
                      <TableCell style={{ padding: '5px' }}>{row.sr_no}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.user_id}</TableCell>
                      <TableCell style={{ padding: '5px' }}><img className="table_profile_img" src={row.profileImageUrl == "" || row.profileImageUrl == undefined ? './images/dummyprofile.jpeg' : row.profileImageUrl}></img></TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.email}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.name}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.phoneNumber}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.country}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.gender}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.age}</TableCell>
                      {/* <TableCell style={{ padding: '5px' }}>{row.religion}</TableCell> */}
                      {/* <TableCell style={{ padding: '5px' }}><img className="table_profile_img" src={imagePaths.chat_logo}></img></TableCell> */}
                    </TableRow>
                  ))
                )}
                </TableBody>
              </Table>
            </TableContainer>
            {searchQuery === '' && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <ButtonGroup>
                <Button className='buttonCustom' style={{ marginLeft: '20px', textAlign: 'center' }} variant="contained" onClick={handlePreviousPage}>Prev</Button>
                <Button className='buttonCustom' variant="contained" onClick={handleNextPage}>Next</Button>
              </ButtonGroup>
            </div>
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingUser;
