import React from 'react';
import '../../src/Sidebar.css';
import { NavLink } from "react-router-dom";

function Sidebar() {
  const accountType = localStorage.getItem('type');

  return (

    <div className="sidebar">
    {/* Sidebar content goes here */}
    <ul>
      <NavLink exact to="/home" className="sidebar-link">
        <li className='outerli'><label>Approved Users</label></li>
      </NavLink>
      <NavLink exact to="/pendinguser" className="sidebar-link" >
        <li className='outerli'><label>Pending Users</label></li>
      </NavLink>
      <NavLink exact to="/reportedUsers" className="sidebar-link">
        <li className='outerli'><label>Reported Users</label></li>
      </NavLink>
      {accountType === 'staff' && (
      <NavLink exact to="/assignedmembers" className="sidebar-link">
        <li className='outerli'><label>Assigned Users</label></li>
      </NavLink>
      )}
      {/* {accountType === 'staff' && ( */}
      <NavLink exact to="/chat" className="sidebar-link">
        <li className='outerli'><label>Chat</label></li>
      </NavLink>
      {/* )} */}
      
      {accountType === 'admin' && (
        <NavLink exact to="/staffList" className="sidebar-link">
          <li className='outerli'><label>Staff List</label></li>
        </NavLink>
      )}
      {/* Add more sidebar items as needed */}
    </ul>
  </div>
  );
}

export default Sidebar;
