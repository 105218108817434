import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getAssignedMemberList, getReportedSearchUserList, getReportedUserList, searchUserAssigned } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';

const AssignedMembers = () => {
    const admin_id = localStorage.getItem('admin_id');
  const navigate = useNavigate();
  const limit = 15;
  const isApproved = true;
  const [data, setData] = useState([]);
  const [startAfter, setStartAfter] = useState(null);
  const [endBefore, setEndBefore] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [loading, setLoading] = useState(false);
  const handleSearchClick = async () => {
    logMessage(searchQuery)
    setLoading(true);
    try {
      if (searchQuery != "") {
        const userList = await searchUserAssigned(searchQuery, data);
        logMessage(userList)
        setData(userList);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching user data:', error);
    } finally {
      // console.log();
      setLoading(false);
    }

  }

  const clearSearch = async (e) => {
    if (searchQuery !== "") {
        window.location.reload();
        // setLoading(true);
        // setSearchQuery("");
        // setData([]);
        // const dataAssignedUsers = await getAssignedMemberList(admin_id);
        // logMessage(dataAssignedUsers);
        // setData(dataAssignedUsers);
     
    }
  };

  const handleRowClick = (user_id) => {
    navigate("/userdetail?user_id=" + user_id);
    // Add your logic here for what should happen when the row is clicked
  };

  const handlePreviousPage = async () => {
    // console.log(endBefore);
    // setLoading(true);
    const { userList, firstVisible: firstDoc, lastVisible: lastDoc } = await getReportedUserList(limit, null, endBefore, isApproved);
    setData(userList);
    setStartAfter(lastDoc);
    setEndBefore(firstDoc);
    // setLoading(false);
  };

  const handleNextPage = async () => {
    // setLoading(true);
    const { userList, firstVisible: firstDoc, lastVisible: lastDoc } = await getReportedUserList(limit, startAfter, null, isApproved);
    setData(userList);
    setStartAfter(lastDoc);
    setEndBefore(firstDoc);
    // setLoading(false);
  };
  useEffect(() => {
    // setLoading(true);

    const fetchData = async () => {
      setLoading(true);
      try {
        const dataAssignedUsers = await getAssignedMemberList(admin_id);
        logMessage(dataAssignedUsers);
        setData(dataAssignedUsers);
        // setStartAfter(lastDoc);
        // setEndBefore(firstDoc);
        // setLoading(false);
        // setIsDataFetched(true);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching user data:', error);
      } finally {
        // console.log();
        setLoading(false);
      }
    };
    fetchData();

    // if (!isDataFetched) {
    //   
    // }
  }, []);
  // const data = [
  //   { id: 1, name: 'John Doe', profession: 'Software Engineer',profession: 'Software Engineer' },
  //   { id: 2, name: 'Jane Smith', profession: 'Data Scientist',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   { id: 3, name: 'Alice Johnson', profession: 'Web Developer',profession: 'Software Engineer' },
  //   // Add more demo data as needed
  // ];
  return (
    <div className="inside-page-container">
      <Navbar />
      <Sidebar />
      <div className='body-page-container'>
        {/* {loading && (
        <div className="loader-container">
         <Bars color="#36454F"  loading={loading} height="40" width="40"/>
        </div>
      )} */}
        <div className="body-content">
          <div className="admin-header">
            <h4>Assigned Users</h4>
          </div>
          <div className="body-content-inside">
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }} className="searchBoxContainer">
              <input type="text"
                placeholder='search'
                style={{ width: '300px', padding: '4px' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              ></input>
              <span style={{ marginLeft: '3px', textAlign: 'center', cursor: 'pointer' }} onClick={handleSearchClick}><img style={{ width: '16px' }} src="./images/search.png"></img> </span >
              <span style={{ marginLeft: '3px', textAlign: 'center', cursor: 'pointer', fontSize: '8px' }} onClick={clearSearch} >clear</span>
            </div>
            {loading && (
              <div className="loader-container">
                <Bars color="#36454F" loading={loading} height="40" width="40" />
              </div>
            )}
            <TableContainer component={Paper} style={{ marginTop: '0px', border: 'solid 1px #0303' }}>
              <Table>
                <TableHead className='head_table'>
                  <TableRow>
                    <TableCell style={{ padding: '5px' }}>Sr No</TableCell>
                    <TableCell style={{ padding: '5px' }}>Profile</TableCell>
                    <TableCell style={{ padding: '5px' }}>UID</TableCell>
                    <TableCell style={{ padding: '5px' }}>Email</TableCell>
                    <TableCell style={{ padding: '5px' }}>Name</TableCell>
                    
                    {/* <TableCell style={{ padding: '5px' }}>Gender</TableCell> */}
                    {/* <TableCell style={{ padding: '5px' }}>License Type</TableCell> */}
                    {/* <TableCell style={{ padding: '5px' }}>City/Country</TableCell>
                <TableCell style={{ padding: '5px' }}>Status</TableCell>
                <TableCell style={{ padding: '5px' }}>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading && (
                    data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>No data available</TableCell>
                      </TableRow>
                    ) : (
                      data.map((row) => (
                        <TableRow key={row.userId} onClick={() => handleRowClick(row.userId)} className="hover-row">
                          <TableCell style={{ padding: '5px' }}>{row.sr_no}</TableCell>
                          <TableCell style={{ padding: '5px' }}><img className="table_profile_img" src={row.image == "" || row.image == undefined ? './images/dummyprofile.jpeg' : row.image}></img></TableCell>
                          <TableCell className="chat_profile"  style={{ padding: '5px' }}>{row.userId}</TableCell>
                          <TableCell className="chat_profile" style={{ padding: '5px' }}>{row.email}</TableCell>
                          <TableCell className="chat_profile"  style={{ padding: '5px' }}>{row.name}</TableCell>
                        </TableRow>
                      ))
                    )
                  )}

                </TableBody>
              </Table>
            </TableContainer>
            {/* <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <ButtonGroup>
                <Button className='buttonCustom' style={{ marginLeft: '20px', textAlign: 'center' }} variant="contained" onClick={handlePreviousPage}>Prev</Button>
                <Button className='buttonCustom' variant="contained" onClick={handleNextPage}>Next</Button>
              </ButtonGroup>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignedMembers;
