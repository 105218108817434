import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getStaffList, getUserList, getUserListSearch } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';

const StaffList = () => {
  const navigate = useNavigate();
  const limit = 20;
  const isApproved= true;
  const [data, setData] = useState([]);
  const [startAfter, setStartAfter] = useState(null);
  const [endBefore, setEndBefore] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useState(false);

  const handleRowClick = (user_id) => {
    navigate("/staffdetail?user_id=" + user_id);
  };
  const addNewStaff = ()=>{
    navigate("/addNewStaff");
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const { userList,firstVisible:firstDoc, lastVisible: lastDoc } = await getStaffList();
        setData(userList);
        setStartAfter(lastDoc);
        setEndBefore(firstDoc);
        // setIsDataFetched(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        // console.log();
        setLoading(false);
      }
    };
    
    if (!isDataFetched) {
      fetchData();
    }
  }, []);

  return (
    <div className="inside-page-container">
      <Navbar />
      <Sidebar />
      <div className='body-page-container'>
      {/* {loading && (
        <div className="loader-container">
         <Bars color="#36454F"  loading={loading} height="40" width="40"/>
        </div>
      )} */}
        <div className="body-content">
          <div className="admin-header">
            <h4>Staff List</h4>
                <span className="start_chat" onClick={addNewStaff}>Add New + 
                          
                        </span>
          </div>
          {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
          <div className="body-content-inside">
            {/* <div style={{marginBottom:'10px', display:'flex', alignItems:'center'}}className="searchBoxContainer">
              <input type="text" 
              placeholder='search'
              style={{width:'300px', padding:'4px'}}
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
              ></input>
              <span style={{ marginLeft: '3px', textAlign: 'center', cursor:'pointer' }} onClick={handleSearchClick}><img style={{width:'16px'}} src="./images/search.png"></img> </span >
            </div> */}
            <TableContainer component={Paper} style={{ marginTop: '0px', border: 'solid 1px #0303' }}>
              <Table>
                <TableHead className='head_table'>
                  <TableRow>
                    <TableCell style={{ padding: '5px' }}>Sr No</TableCell>
                    <TableCell style={{ padding: '5px' }}>UID</TableCell>
                    <TableCell style={{ padding: '5px' }}>Profile Image</TableCell>
                    <TableCell style={{ padding: '5px' }}>Email</TableCell>
                    <TableCell style={{ padding: '5px' }}>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}></TableCell>
                    </TableRow>
                  ) : (
                  data.map((row) => (
                    
                    <TableRow key={row.user_id} className="hover-row" onClick={() => handleRowClick(row.user_id)}>
                      <TableCell style={{ padding: '5px' }}>{row.sr_no}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.user_id}</TableCell>
                      <TableCell style={{ padding: '5px' }}><img className="table_profile_img" src={row.profileImageUrl == "" || row.profileImageUrl == undefined ? './images/dummyprofile.jpeg' : row.profileImageUrl}></img></TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.email}</TableCell>
                      <TableCell style={{ padding: '5px' }}>{row.name}</TableCell>
                      {/* <TableCell style={{ padding: '5px' }}>{row.religion}</TableCell> */}
                    </TableRow>
                  ))
                )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffList;
