import React, { useRef, useEffect, useState, Component } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useNavigate } from "react-router-dom";
import { retrieveChat, getMessageCollectionRef, sendMessage, uploadImage, getConversationList, getConversationListNew, getAssignedMemberList, sendFirstMessage } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import imagePaths from '../imagePaths';
import { useLocation } from "react-router-dom";
import { logMessage } from '../utils/helpers';
import '../chat.css';
// const firebase = require('firebase/app');
// require('firebase/firestore');

const Chat = () => {
    logMessage(window.innerHeight);

    const admin_id = localStorage.getItem('admin_id');
    const messageContainerRef = useRef(null);
    const [convName, setConvName] = useState("No User Selected");
    const [profileImage, setProfileImage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [premiumUser, setPremiumUser] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const searchParameter = new URLSearchParams(location.search);
    const userId = searchParameter.get('user_id');
    const [data, setData] = useState([]);
    const [chatId, setChatId] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [clickedItemId, setClickedItemId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const handleProfileClick = (user_id) => {
        logMessage(user_id);
        if (user_id != undefined && user_id != "") {
            navigate("/userdetail?user_id=" + user_id);
        }

    };
    const handleSearchChange = (e) => {

        setSearchQuery(e.target.value.toLowerCase());
        if (e.target.value.toLowerCase() == "") {
            setFilteredConversations(conversations);
        }


    };

    const handleSearchClick = (e) => {
        if (searchQuery == "") {
            setFilteredConversations(conversations);
        } else {
            // setSearchQuery(e.target.value.toLowerCase());
            setFilteredConversations(conversations.filter(conversation =>
                conversation.email.toLowerCase().includes(searchQuery) ||
                conversation.userId.toLowerCase().includes(searchQuery)
            ));
        }
    }

    const scrollToBottom = () => {
        logMessage("kkk")
        // messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' }); 
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    };
    useEffect(() => {
        if (selectedChatId !== null) {
            handleConvClick();
        }
    }, [selectedChatId]);

    const handleConvClick = async () => {

        logMessage(selectedChatId);
        try {
            if (selectedChatId) {
                logMessage(selectedChatId);
                await fetchData(selectedChatId);
            } else {
                // alert("empty message");
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    const handleChatClick = async () => {
        try {
            const messageSent = message;
            if (messageSent !== "") {
                setMessage("")
                const type = "text";
                if (chatId == "") {
                    const result = await sendFirstMessage(userId, messageSent, type);
                    if (result != "") {
                        setChatId(result)
                        const messagesRef = getMessageCollectionRef(result);

                        const unsubscribe = messagesRef.onSnapshot(snapshot => {
                            const updatedMessages = snapshot.docs.map(doc => doc.data());
                            setData(updatedMessages);
                            logMessage(updatedMessages);
                        });
                       
                    }
                } else {
                    await sendMessage(chatId, messageSent, type);
                }
                fetchConversationData();
                scrollToBottom();
                logMessage('Message sent');
            } else {
                // alert("empty message");
            }

        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const fetchData = async (userIdParam) => {
        try {
            setIsDataFetched(true);
            const chatData = await retrieveChat(userIdParam);
            // setLoading(true);
            if (chatData !== null) {
                logMessage(chatData)
                setConvName(chatData.name)
                setProfileImage(chatData.image)
                setData(chatData.messages);
                setChatId(chatData.chatId);
                setPremiumUser("(" + chatData.sivvdMember + ")");
                // scrollToBottom();
                if(chatData.chatId != ""){
                const messagesRef = getMessageCollectionRef(chatData.chatId);

                const unsubscribe = messagesRef.onSnapshot(snapshot => {
                    const updatedMessages = snapshot.docs.map(doc => doc.data());
                    setData(updatedMessages);
                    logMessage(updatedMessages);
                });
            }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    const fetchConversationData = async () => {
        try {
            setIsDataFetched(true);
            const chatData = await getConversationList(admin_id);
            logMessage("conversationlist");
            logMessage(chatData);
            if (chatData.length > 0) {
                setConversations(chatData)
                setFilteredConversations(chatData);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };
    useEffect(() => {

        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const userIdParam = searchParams.get('user_id');
        fetchConversationData();
        if (userIdParam && userIdParam != "") {
            setSelectedChatId(userIdParam);
            fetchData(selectedChatId);
        }

    }, []);
    useEffect(() => {
        scrollToBottom();
    }, [data, chatId]);

    const handleImageChange = async (e) => {
        if (e.target.files[0]) {
            logMessage("yesitruns");
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            const ImageUrl = await uploadImage(e.target.files[0]);
            if (ImageUrl != "") {
                await setImageUrl(ImageUrl);
                const type = "image";
                if (chatId == "") {
                    const result = await sendFirstMessage(userId, ImageUrl, type);
                    if (result != "") {
                        setChatId(result)
                        const messagesRef = getMessageCollectionRef(result);

                        const unsubscribe = messagesRef.onSnapshot(snapshot => {
                            const updatedMessages = snapshot.docs.map(doc => doc.data());
                            setData(updatedMessages);
                            logMessage(updatedMessages);
                        });
                    }
                } else {
                    await sendMessage(chatId, ImageUrl, type);
                }
                fetchConversationData();
                scrollToBottom();

            }

        }
    };

    return (
        <div className="inside-page-container">
            <Navbar />
            <Sidebar />
            <div style={{ overflow: "hidden" }} className='body-page-container'>
                {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
                <div className="body-content ">
                    {/* <div className="admin-header">
                        <h4>Chat</h4>
                    </div> */}
                    <div className="body-content-inside chat-content-inside ">
                        <div className='outerconversationbox'>
                            <div className="msg-head">
                                <h4 style={{ textAlign: "center" }}>Assigned Users</h4>
                                <input
                                    type="text"
                                    className='searchBox'
                                    placeholder="search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <span style={{ marginLeft: '3px', textAlign: 'center', cursor: 'pointer' }} onClick={handleSearchClick}><img style={{ width: '16px' }} src="./images/search.png"></img> </span >
                            </div>
                            <div className='conversationbox'>
                                <ul>
                                    {filteredConversations.map(conversation => (
                                        <li key={conversation.chatId} onClick={() => setSelectedChatId(conversation.userId)} className={`conversation-single-box ${conversation.userId === selectedChatId ? 'clicked' : ''}`}>
                                            <div className='image-section'>
                                                <img className="img-fluid" style={{ width: '32px', height: '32px', borderRadius: '20px', marginRight: '10px' }} src={conversation.image == "" || conversation.image == undefined ? './images/dummyprofile.jpeg' : conversation.image}></img>
                                            </div>
                                            <div className='detail-section'>
                                                <div className="name">{conversation.name} <span className='premium_text'>({conversation.sivvdMember})</span></div>
                                                <div className="last-message">  {conversation.lastMessage?.type === "text" ? conversation.lastMessage?.text : conversation.lastMessage ? 'Photo' : ''}</div>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                        <div className="chatbox">
                            <div className="modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="msg-head">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="d-flex align-items-center">
                                                    {/* <span className="chat-icon"><img className="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg" alt="image title" /></span> */}
                                                    <div className="flex-shrink-0">
                                                        {profileImage ? (
                                                            <img
                                                                onClick={() => handleProfileClick(selectedChatId)}
                                                                className="img-fluid chat_profile"
                                                                style={{ width: '32px', height: '32px', borderRadius: '20px', marginRight: '10px' }}
                                                                src={profileImage}
                                                                alt="Profile"
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() => handleProfileClick(selectedChatId)}
                                                                className="img-fluid chat_profile"
                                                                style={{ width: '32px', height: '32px', borderRadius: '20px', marginRight: '10px' }}
                                                                src='./images/dummyprofile.jpeg'
                                                                alt="Default Profile"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h3 className="chat_profile" onClick={() => handleProfileClick(selectedChatId)}>{convName} <span className='premium_text'>{premiumUser}</span></h3>
                                                        {/* <p>front end developer</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-body" ref={messageContainerRef}>
                                        <div className="msg-body" >
                                            {!loading && (
                                                data.length === 0 ? (
                                                    <p className='nomessages'>No messages</p>
                                                ) : (
                                                    <ul>
                                                        {data.map((message, index) => (<li key={index} className={`${message.sentBy === admin_id ? "repaly" : "sender"} ${message.type === "image" ? "image-message" : ""}`}>
                                                            {/* <p>{message.text}</p> */}
                                                            {message.type === "text" ? (
                                                                <p>{message.text}</p>
                                                            ) : (
                                                                <img className="messageImage" src={message.text} alt="Image" />
                                                            )}
                                                            {/* <span className="time">12:00</span> */}
                                                        </li>
                                                        ))}
                                                    </ul>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="send-box">
                                        <form action="">
                                            <input
                                                type="text"
                                                className="form-control"
                                                aria-label="message…"
                                                placeholder="Write message…"
                                                value={message}
                                                onChange={(event) => setMessage(event.target.value)}
                                            />
                                            <button type="button" onClick={handleChatClick}><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                                        </form>

                                        <div className="send-btns">
                                            <div className="attach">
                                                <div className="button-wrapper">
                                                    <span className="label">
                                                        <img className="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/upload.svg" alt="image title" /> send image
                                                    </span>
                                                    <input type="file"
                                                        accept="image/*"
                                                        onChange={(event) => handleImageChange(event)}
                                                        name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                                                </div>
                                                {/* <button >Upload Image</button> */}

                                                {/* <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select template</option>
                                    <option>Template 1</option>
                                    <option>Template 2</option>
                                </select> */}

                                                {/* <div className="add-apoint">
                                    <a href="#" data-toggle="modal" data-target="#exampleModal4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z" fill="#7D7D7D"></path><path d="M11.5 8.5H4.5C4.224 8.5 4 8.276 4 8C4 7.724 4.224 7.5 4.5 7.5H11.5C11.776 7.5 12 7.724 12 8C12 8.276 11.776 8.5 11.5 8.5Z" fill="#7D7D7D"></path><path d="M8 12C7.724 12 7.5 11.776 7.5 11.5V4.5C7.5 4.224 7.724 4 8 4C8.276 4 8.5 4.224 8.5 4.5V11.5C8.5 11.776 8.276 12 8 12Z" fill="#7D7D7D"></path></svg> Appoinment</a>
                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div >
    );
}

export default Chat;
