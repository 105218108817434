import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useLocation } from "react-router-dom";
import { getStaffDetail, updateStaffDetail, updateStaffDetailByAdmin, updateUserDetail, uploadImage } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';
import imagePaths from '../imagePaths';
import { useNavigate } from 'react-router-dom';

const MyProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user_id, setUserId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");
    // const [uploadedImage, setUploadedImage] = useState("");
    // const [assignedMembers, setAssignedMembers] = useState([]);
    // const [allUsers, setUsers] = useState([]);
    const [profileImageUrlInstance, setProfileImageUrlInstance] = useState(null);

    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const limit = 15;
    // const handleCheckboxChange = (uid) => {
    //     const updatedAssignedUsers = assignedMembers.includes(uid)
    //       ? assignedMembers.filter(user => user !== uid)
    //       : [...assignedMembers, uid];
    //       setAssignedMembers(updatedAssignedUsers);
    // };

    const handleImageData = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (
            profileImageUrlInstance === null
        ) {
            handleUpdateData(profileImageUrl);
        } else {
            if (profileImageUrlInstance !== null) {
                await uploadImageInside(profileImageUrlInstance, 1);
            }
            logMessage('handleUpdateDatafinal')
            
        }
    };

    const handleUpdateData = async (imageurl) => {
        setLoading(true);
        // e.preventDefault();
        try {
            
            logMessage("uploadedImagefinal"+imageurl);
            const updated = await updateStaffDetail(user_id, name, imageurl);
            if (updated) {
                alert("Profile Updated Successfully")
            } else {
                alert("Error in updating profile data")
                setLoading(false);
            }
            setLoading(false);

        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    };

    const handleEditIconClick = (id) => {
        logMessage(id)
        const elementToClick = document.getElementById(id);
    };

    const handleInputImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const imageUrl = event.target.result;
                logMessage(imageUrl)
                switch (index) {
                    case 1:
                        setProfileImageUrl(imageUrl);
                        // setUploadedImage(file)
                        setProfileImageUrlInstance(file);
                        break;
                    default:
                        console.error(`Invalid index: ${index}`);
                }
            };
            fileReader.readAsDataURL(file);
        }
    };
    const uploadImageInside = async (image, index) => {
        if (image) {
            const imageUrl = await uploadImage(image);
            handleUpdateData(imageUrl);
            logMessage("imageUrl"+imageUrl);
            // switch (index) {
            //     case 1:
                    setProfileImageUrl(imageUrl);
                    logMessage(profileImageUrl)
                    // setUploadedImage(imageUrl)
                    setProfileImageUrlInstance(null);
                    // break;
                // default:
                //     console.error(`Invalid index: ${index}`);
            // }
        }
    };

    useEffect(() => {
        setLoading(true);
    
        const userIdParam = localStorage.getItem('admin_id');

        const fetchData = async () => {
            try {
                const data = await getStaffDetail(userIdParam);
                setUserId(userIdParam);
                setName(data.name)
                setEmail(data.email);
                logMessage("will this runs again")
                setProfileImageUrl(data.profileImageUrl);
                // setUploadedImage(data.profileImageUrl)   
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, []);

    // const handleChatClick = () => {
    //     navigate(`/chat?user_id=${user_id}`);
    // };

    return (
        <div className="inside-page-container">
            <Navbar />
            <Sidebar />
            <div className='body-page-container'>
                {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
                <div className="body-content">
                    <div className="admin-header">
                        <h4>My Profile</h4>
                        {/* <span className="start_chat" onClick={handleChatClick}>Start Chat
                          
                        </span> */}
                    </div>
                    <div className="body-content-inside">
                        <form onSubmit={handleImageData}>
                            <div className='card_container'>
                                <div className='left_card'>

                                    <p>General Information</p>
                                    <div className='inside_form_container'>
                                        <div className='input_fields_container'>
                                            <div className="input_row" style={{ width: "110%" }} >
                                                <label htmlFor="uid">UID</label>
                                                <input type="text" id="uid" value={user_id} readOnly={true} />
                                            </div>

                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="firstName">Name</label>
                                                <input type="text" id="firstName" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" id="email" value={email} readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='user_fields_container'>
                                        <p>Assigned Users</p>
                                        {allUsers.map(user => (
                                            <div key={user.uid} className="input_row input_users">

                                                <input
                                                    type="checkbox"
                                                    id={`user-${user.uid}`}
                                                    checked={assignedMembers.includes(user.uid)}
                                                    onChange={() => handleCheckboxChange(user.uid)}
                                                />
                                                <label htmlFor={`user-${user.uid}`}>{user.email}</label>
                                            </div>
                                        ))}
                                    </div> */}
                                    <div className='outerButtonContainer' style={{ marginTop: '10px' }}>
                                        <button type="submit" className="updateUser innerButtonContainer" disabled={loading}>
                                            {loading ? (
                                                <ThreeDots color="#000000" height={30} width={30} />
                                            ) : (
                                                'Update'
                                            )}
                                        </button>
                                    </div>
                                    {/* <button type="submit" className="updateUser" disabled={loading}>
                                        {loading ? (
                                            <ThreeDots color="#000000" height={30} width={30} />
                                        ) : (
                                            'Update'
                                        )}
                                    </button> */}

                                </div>
                                <div className='right_card'>
                                    <div className='image_container'>
                                        <img
                                            alt="profile_img"
                                            className='profile_img'
                                            src={profileImageUrl !== "" ? profileImageUrl : "./images/dummyprofile.jpeg"}>
                                        </img>
                                        <div className='edit_icon extraProfile'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(1, e)} id="profile_image" className="profile_image_class" name="profile_image" accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' 
                                             onClick={handleEditIconClick("profile_image")}
                                             src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyProfile;
