import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useLocation } from "react-router-dom";
import { getUserDetail, updateImageOnly, updateUserDetail, uploadImage } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';
import imagePaths from '../imagePaths';
import { useNavigate } from 'react-router-dom';

const UserDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [bio, setMatchMakerBio] = useState("");
    const [board, setBoard] = useState("");
    const [userBio, setBio] = useState("");
    const [boardPosition, setBoardPosition] = useState("");
    const [careerFlex, setCareerFlex] = useState("");
    const [children, setChildren] = useState("");
    const [company, setCompany] = useState("");
    const [currentPosition, setCurrentPosition] = useState("");
    const [drinking, setDrinking] = useState("");
    const [education, setEducation] = useState("");
    const [familyFlex, setFamilyFlex] = useState("");
    const [heightInches, setHeightInches] = useState("");
    const [homes, setHomes] = useState("");
    const [income, setIncome] = useState("");
    const [memberships, setMemberships] = useState("");
    const [starSign, setStarSign] = useState("");
    const [relocateFlex, setRelocateFlex] = useState("");
    const [swipedCount, setSwipedCount] = useState("");
    const [priorPosition, setPriorPosition] = useState("");
    const [politics, setPolitics] = useState("");
    const [location1, setLocation1] = useState("");
    const [location2, setLocation2] = useState("");
    const [location3, setLocation3] = useState("");
    const [smoking, setSmoking] = useState("");
    const [sivvdMember, setSivvdMember] = useState("");
    // const [photosPrivate, setPhotosPrivate] = useState("");
    const [netWorth, setNetWorth] = useState("");
    const [dob, setDob] = useState("");
    const [userUid, setUserId] = useState("");
    const [isApproved, setIsApproved] = useState(false);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [age, setAge] = useState(0);
    const [country, setCountry] = useState("");
    const [gender, setGender] = useState("");
    const [religion, setReligion] = useState("");
    const [maxHeight, setMaxHeight] = useState(0);
    const [minHeight, setMinHeight] = useState(0);
    const [maxAge, setMaxAge] = useState(0);
    const [minAge, setMinAge] = useState(0);
    const [preference, setPreference] = useState("");
    const [martialStatus, setMartialStatus] = useState("");
    const [secondImageUrl, setSecondImageUrl] = useState("");
    const [thirdImageUrl, setThirdImageUrl] = useState("");
    const [fourthImageUrl, setFourthImageUrl] = useState("");
    const [fifthImageUrl, setFifthImageUrl] = useState("");
    const [sixthImageUrl, setSixthImageUrl] = useState("");
    const [seventhImageUrl, setSeventhImageUrl] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");
    const [secondImageUrlInstance, setSecondImageUrlInstance] = useState(null);
    const [thirdImageUrlInstance, setThirdImageUrlInstance] = useState(null);
    const [fourthImageUrlInstance, setFourthImageUrlInstance] = useState(null);
    const [fifthImageUrlInstance, setFifthImageUrlInstance] = useState(null);
    const [sixthImageUrlInstance, setSixthImageUrlInstance] = useState(null);
    const [seventhImageUrlInstance, setSeventhImageUrlInstance] = useState(null);
    const [profileImageUrlInstance, setProfileImageUrlInstance] = useState(null);

    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const limit = 15;
    // const isApproved = false;
    const [data, setData] = useState([]);

    const handleImageData = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (
            secondImageUrlInstance === null &&
            thirdImageUrlInstance === null &&
            fourthImageUrlInstance === null &&
            fifthImageUrlInstance === null &&
            sixthImageUrlInstance === null &&
            seventhImageUrlInstance === null &&
            profileImageUrlInstance === null
        ) {

            // All instances are null, run another function
            handleUpdateData();
            // logMessage('handleUpdateData')
        } else {
            // Upload each image instance that is not null

            if (secondImageUrlInstance !== null) {
                await uploadImageInside(secondImageUrlInstance, 2);
                logMessage('handleUpdateData2')
            }
            if (thirdImageUrlInstance !== null) {
                await uploadImageInside(thirdImageUrlInstance, 3);
                logMessage('handleUpdateData3')
            }
            if (fourthImageUrlInstance !== null) {
                await uploadImageInside(fourthImageUrlInstance, 4);
            }
            if (fifthImageUrlInstance !== null) {
                await uploadImageInside(fifthImageUrlInstance, 5);
            }
            if (sixthImageUrlInstance !== null) {
                await uploadImageInside(sixthImageUrlInstance, 6);
            }
            if (seventhImageUrlInstance !== null) {
                await uploadImageInside(seventhImageUrlInstance, 7);
            }
            if (profileImageUrlInstance !== null) {
                await uploadImageInside(profileImageUrlInstance, 1);
            }
            // After uploading images, call handleUpdateData
            logMessage('handleUpdateDatafinal')
            setLoading(false);
            handleUpdateData();
        }
    };

    const handleUpdateData = async () => {
        setLoading(true);
        // e.preventDefault();
        try {
            // setLoading(false);
            // console.log(isApproved)
            // console.log(bio)
            // setLoading(true);
            const updated = await updateUserDetail(userUid, bio, isApproved, gender, name, country, religion, maxHeight, minHeight, maxAge, minAge, preference, martialStatus, age, dob, lastName,board, userBio, boardPosition, careerFlex, children, company, currentPosition, drinking, education, familyFlex, heightInches, homes, income, memberships, starSign, relocateFlex, swipedCount, priorPosition, politics, location1, location2, location3, netWorth, smoking,sivvdMember);
            if (updated) {
                alert("Profile Updated Successfully")
            } else {
                alert("Error in updating profile data")
            }
            setLoading(false);
            // console.log(isAdmin);
            // if (isAdmin) {
            //     alert("Login success");
            //     setLoading(false);
            //     navigate("/home");
            //     // Proceed with further actions for logged-in admin
            // } else {
            //     alert("Invalid credentials");
            //     setLoading(false);
            // }
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    };
    const handleChange = (event) => {
        const selectedDate = event.target.value;
        logMessage(selectedDate)
        setDob(selectedDate);
        calculateAge(selectedDate);
    };
    const calculateAge = (selectedDate) => {

        const currentDate = new Date();
        const birthDate = new Date(selectedDate);
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        setAge(age)
        logMessage(age)

    };
    const handleEditIconClick = (id) => {
        logMessage(id)
        const elementToClick = document.getElementById(id);
    };

    const handleInputImageChange = (index, value) => {
        const file = value.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const imageUrl = event.target.result;
                switch (index) {
                    case 1:
                        setProfileImageUrl(imageUrl);
                        setProfileImageUrlInstance(file);
                        break;
                    case 2:
                        setSecondImageUrl(imageUrl);
                        setSecondImageUrlInstance(file);
                        break;
                    case 3:
                        setThirdImageUrl(imageUrl);
                        setThirdImageUrlInstance(file);
                        break;
                    case 4:
                        setFourthImageUrl(imageUrl);
                        setFourthImageUrlInstance(file);
                        break;
                    case 5:
                        setFifthImageUrl(imageUrl);
                        setFifthImageUrlInstance(file);
                        break;
                    case 6:
                        setSixthImageUrl(imageUrl);
                        setSixthImageUrlInstance(file);
                        break;
                    case 7:
                        setSeventhImageUrl(imageUrl);
                        setSeventhImageUrlInstance(file);
                        break;
                    default:
                        console.error(`Invalid index: ${index}`);
                }
            };
            fileReader.readAsDataURL(file);
        }
    };
    const uploadImageInside = async (image, index) => {
        if (image) {
            const imageUrl = await uploadImage(image);
            await updateImageOnly(userUid,imageUrl, index )
            // switch (index) {
            //     case 1:
            //         await updateImageOnly(userUid,imageUrl,index )
            //         setProfileImageUrl(imageUrl);
            //         setProfileImageUrlInstance(null);
            //         break;
            //     case 2:
            //         logMessage("secondcase" + imageUrl)
            //         setSecondImageUrl(imageUrl);
            //         setSecondImageUrlInstance(null);
            //         break;
            //     case 3:
            //         setThirdImageUrl(imageUrl);
            //         setThirdImageUrlInstance(null);
            //         break;
            //     case 4:
            //         setFourthImageUrl(imageUrl);
            //         setFourthImageUrlInstance(null);
            //         break;
            //     case 5:
            //         setFifthImageUrl(imageUrl);
            //         setFifthImageUrlInstance(null);
            //         break;
            //     case 6:
            //         setSixthImageUrl(imageUrl);
            //         setSixthImageUrlInstance(null);
            //         break;
            //     case 7:
            //         setSeventhImageUrl(imageUrl);
            //         setSeventhImageUrlInstance(null);
            //         break;
            //     default:
            //         console.error(`Invalid index: ${index}`);
            // }
        }
    };

    useEffect(() => {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const userIdParam = searchParams.get('user_id');

        const fetchData = async () => {
            try {
                const data = await getUserDetail(userIdParam);
                setData(data);
                setUserId(userIdParam);
                setIsApproved(data.isApproved);
                setMatchMakerBio(data.matchmakerBio);
                setAge(data.age);
                setCountry(data.country);
                setGender(data.gender);
                setMartialStatus(data.maritalStatus);
                setPreference(data.preference);
                setMaxAge(data.maxAge);
                setName(data.name)
                setMinAge(data.minAge);
                setMaxHeight(data.maxHeight);
                setMinHeight(data.minHeight);
                setPhoneNumber(data.phoneNumber);
                setReligion(data.religion);
                setSecondImageUrl(data.secondImageUrl);
                setThirdImageUrl(data.thirdImageUrl);
                setFourthImageUrl(data.fourthImageUrl);
                setFifthImageUrl(data.fifthImageUrl);
                setSixthImageUrl(data.sixthImageUrl);
                setSeventhImageUrl(data.seventhImageUrl);
                setProfileImageUrl(data.profileImageUrl);
                setLastName(data.lastName);
                setBoard(data.board);
                setBio(data.userBio);
                setBoardPosition(data.boardPosition);
                setCareerFlex(data.careerFlex);
                setChildren(data.children);
                setCompany(data.company);
                setCurrentPosition(data.currentPosition);
                setDrinking(data.drinking);
                setEducation(data.education);
                setFamilyFlex(data.familyFlex);
                setHeightInches(data.heightInches);
                setHomes(data.homes);
                setIncome(data.income);
                setMemberships(data.memberships);
                setStarSign(data.starSign);
                setRelocateFlex(data.relocateFlex);
                setSwipedCount(data.swipedCount);
                setPriorPosition(data.priorPosition);
                setPolitics(data.politics);
                setLocation1(data.location1);
                setLocation2(data.location2);
                setLocation3(data.location3);
                setNetWorth(data.netWorth);
                setSmoking(data.smoking)
                setSivvdMember(data.sivvdMember)

                let dobString = `${data.birthYear}-${(data.birthMonth.toString()).padStart(2, '0')}-${(data.birthDay.toString()).padStart(2, '0')}`;
                logMessage(dobString)
                setDob(dobString)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [location]);

    const handleChatClick = () => {
        navigate(`/chat?user_id=${userUid}`);
    };

    return (
        <div className="inside-page-container">
            <Navbar />
            <Sidebar />
            <div className='body-page-container'>
                {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
                <div className="body-content">
                    <div className="admin-header">
                        <h4>User Detail</h4>
                        <span className="start_chat" onClick={handleChatClick}>Start Chat
                            {/* <img className="table_profile_img chat_logo" src={imagePaths.chat_logo}>
                            </img> */}
                        </span>
                    </div>
                    <div className="body-content-inside">
                        <form onSubmit={handleImageData}>
                            <div className='card_container'>
                                <div className='left_card'>

                                    <p>General Information</p>
                                    <div className='inside_form_container'>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="uid">UID</label>
                                                <input type="text" id="uid" value={userUid} readOnly={true} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="board">Sivvd member</label>
                                                <input type="text" id="sivvdmemeber" value={sivvdMember} onChange={(e) => setSivvdMember(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" id="firstName" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" id="email" value={data.email} readOnly={true} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="phone_number">Phone Number</label>
                                                <input type="text" id="phone_number" value={phoneNumber} readOnly={true} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="dob">Dob</label>
                                                <input
                                                    type="date"
                                                    id="dob"
                                                    value={dob}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="age">Age</label>
                                                <input type="text" id="age" value={age} readOnly={true} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="gender">Gender</label>
                                                <input type="text" id="gender" value={gender} onChange={(e) => setGender(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="country">Country</label>
                                                <input type="text" id="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="board">Board</label>
                                                <input type="text" id="board" value={board} onChange={(e) => setBoard(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="religion">Religion</label>
                                                <input type="text" id="religion" value={religion} onChange={(e) => setReligion(e.target.value)} />
                                            </div>
                                           
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="boardPosition">Board Position</label>
                                                <input type="text" id="boardPosition" value={boardPosition} onChange={(e) => setBoardPosition(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="careerFlex">Career Flex</label>
                                                <input type="text" id="careerFlex" value={careerFlex} onChange={(e) => setCareerFlex(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="children">Children</label>
                                                <input type="text" id="children" value={children} onChange={(e) => setChildren(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="company">Company</label>
                                                <input type="text" id="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="currentPosition">Current Position</label>
                                                <input type="text" id="currentPosition" value={currentPosition} onChange={(e) => setCurrentPosition(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="drinking">Drinking</label>
                                                <input type="text" id="drinking" value={drinking} onChange={(e) => setDrinking(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="education">Education</label>
                                                <input type="text" id="education" value={education} onChange={(e) => setEducation(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="familyFlex">Family Flex</label>
                                                <input type="text" id="familyFlex" value={familyFlex} onChange={(e) => setFamilyFlex(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="heightInches">Height Inches</label>
                                                <input type="text" id="heightInches" value={heightInches} onChange={(e) => setHeightInches(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="homes">Homes</label>
                                                <input type="text" id="homes" value={homes} onChange={(e) => setHomes(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="income">Income</label>
                                                <input type="text" id="income" value={income} onChange={(e) => setIncome(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="memberships">Memberships</label>
                                                <input type="text" id="memberships" value={memberships} onChange={(e) => setMemberships(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="starSign">Star Sign</label>
                                                <input type="text" id="starSign" value={starSign} onChange={(e) => setStarSign(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="relocateFlex">Relocate Flex</label>
                                                <input type="text" id="relocateFlex" value={relocateFlex} onChange={(e) => setRelocateFlex(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="swipedCount">Swiped Count</label>
                                                <input type="text" id="swipedCount" value={swipedCount} onChange={(e) => setSwipedCount(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="priorPosition">Prior Position</label>
                                                <input type="text" id="priorPosition" value={priorPosition} onChange={(e) => setPriorPosition(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="politics">Politics</label>
                                                <input type="text" id="politics" value={politics} onChange={(e) => setPolitics(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="location1">Location 1</label>
                                                <input type="text" id="location1" value={location1} onChange={(e) => setLocation1(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="location2">Location 2</label>
                                                <input type="text" id="location2" value={location2} onChange={(e) => setLocation2(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="location3">Location 3</label>
                                                <input type="text" id="location3" value={location3} onChange={(e) => setLocation3(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="netWorth">Net Worth</label>
                                                <input type="text" id="netWorth" value={netWorth} onChange={(e) => setNetWorth(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="smoking">Smoking</label>
                                                <input type="text" id="smoking" value={smoking} onChange={(e) => setSmoking(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className='input_fields_container'>
                                            <div className="input_row_text_area">
                                                <label htmlFor="match_bio">User Bio</label>
                                                <textarea id="match_bio" onChange={(e) => setBio(e.target.value)} value={userBio}  >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Match Maker Information</p>
                                    <div className='inside_form_container'>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="max_height">Max Height</label>
                                                <input type="text" id="max_height" value={maxHeight} onChange={(e) => setMaxHeight(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="min_height">Min Height</label>
                                                <input type="text" id="min_height" value={minHeight} onChange={(e) => setMinHeight(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="max_age">Max Age</label>
                                                <input type="text" id="max_age" value={maxAge} onChange={(e) => setMaxAge(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="min_age">Min Age</label>
                                                <input type="text" id="min_age" value={minAge} onChange={(e) => setMinAge(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="preference">Preference</label>
                                                <input type="text" id="preference" value={preference} onChange={(e) => setPreference(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="martial_status">Martial Status</label>
                                                <input type="text" id="martial_status" value={martialStatus} onChange={(e) => setMartialStatus(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="martial_status">Approved Status</label>
                                                <input type="checkbox" id="switch" checked={isApproved} onChange={(e) => setIsApproved(e.target.checked)} /><label className="inside_label" htmlFor="switch">Toggle</label>
                                            </div>
                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row_text_area">
                                                <label htmlFor="match_bio">Match Maker Bio</label>
                                                <textarea id="match_bio" onChange={(e) => setMatchMakerBio(e.target.value)} value={bio}  >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button type="submit" className="updateUser" disabled={loading}>
                                        {loading ? (
                                            <ThreeDots color="#000000" height={30} width={30} />
                                        ) : (
                                            'Update'
                                        )}
                                    </button> */}

                                </div>
                                <div className='right_card'>
                                    <div className='image_container'>
                                        <img
                                            alt="profile_img"
                                            className='profile_img'
                                            src={profileImageUrl !== "" ? profileImageUrl : "./images/dummyprofile.jpeg"}>
                                        </img>
                                        <div className='edit_icon extraProfile'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(1, e)} id="profile_image" class="profile_image_class" name="profile_image" accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' 
                                             onClick={handleEditIconClick("profile_image")}
                                             src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3>Other images</h3>
                            <div className='grid_image_container'>
                                {secondImageUrl && (
                                    <div className='grid_item'>
                                        <img src={secondImageUrl} alt='Second Image' />
                                        <div className='edit_icon'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(2, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                                {thirdImageUrl && (
                                    <div className='grid_item'>
                                        <img src={thirdImageUrl} alt='Third Image' />
                                        <div className='edit_icon'>
                                            <input type="file" onChange={(e) => handleInputImageChange(3, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                                {fourthImageUrl && (
                                    <div className='grid_item'>
                                        <img src={fourthImageUrl} alt='Fourth Image' />
                                        <div className='edit_icon'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(4, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                                {fifthImageUrl && (
                                    <div className='grid_item'>
                                        <img src={fifthImageUrl} alt='Fifth Image' />
                                        <div className='edit_icon'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(5, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                                {sixthImageUrl && (
                                    <div className='grid_item'>
                                        <img src={sixthImageUrl} alt='Sixth Image' />
                                        <div className='edit_icon'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(6, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                                {seventhImageUrl && (
                                    <div className='grid_item'>
                                        <img src={seventhImageUrl} alt='Seventh Image' />
                                        <div className='edit_icon'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(7, e)} id="profile_image"
                                                class="profile_image_class"
                                                name="profile_image"
                                                accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='outerButtonContainer'>
                                <button type="submit" className="updateUser innerButtonContainer" disabled={loading}>
                                    {loading ? (
                                        <ThreeDots color="#000000" height={30} width={30} />
                                    ) : (
                                        'Update'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetail;
