import React, {useEffect} from 'react';
import '../../src/Navbar.css';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const handleMyprofile = () =>{
    navigate(`/myprofile`);
  }
  const handleSignOut = () => {
    // Logic to sign out the user and clear token from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('admin_id');
    navigate('/');
  };
  return (
    <div className="navbar">
      {/* Navbar content goes here */}
      <span className='logo-text'>Sivvd </span>
      <ul>
      <li style={{marginRight:'10px', background:'none', color:'#fff'}} onClick={handleMyprofile}>My profile</li>
      <li onClick={handleSignOut}>Sign Out</li>
        {/* Add more navbar items as needed */}
      </ul>
    </div>
  );
}

export default Navbar;
