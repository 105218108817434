import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useLocation } from "react-router-dom";
import { assignUid, deleteAssignedUserDatabase, getStaffDetail, searchUser, updateStaffDetailByAdmin, updateUserDetail, uploadImage } from '../services/firebase';
import { ThreeDots, Bars } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';
import imagePaths from '../imagePaths';
import { useNavigate } from 'react-router-dom';

const StaffDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user_id, setUserId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");
    const [assignedMembers, setAssignedMembers] = useState([]);
    const [allUsers, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [searchUid, setSearchUid] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [profileImageUrlInstance, setProfileImageUrlInstance] = useState(null);

    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const limit = 15;
    const handleCheckboxChange = (uid) => {
        const updatedAssignedUsers = assignedMembers.includes(uid)
            ? assignedMembers.filter(user => user !== uid)
            : [...assignedMembers, uid];
        setAssignedMembers(updatedAssignedUsers);
    };

    const seeProfile = (user_id) => {
        navigate("/userdetail?user_id=" + user_id);
      };
    const deleteAssignedUser = async (uid)=>{
        try {
            logMessage(uid);
            const confirmed = window.confirm('Are you sure you want to remove this user?');
            if (confirmed) {
                setLoading(true);
                const delete_result = await deleteAssignedUserDatabase(uid, user_id);
                if (delete_result) {
                    // window.location.reload()
                    const data = await getStaffDetail(user_id);
                    setUserId(user_id);
                    setName(data.name)
                    setEmail(data.email);
                    setAssignedMembers(data.assignedUsers);
                    setProfileImageUrl(data.profileImageUrl);
                    setLoading(false);
                } else {
                    alert("error in deleting user")
                    setLoading(false);
                }

            }
        }catch (error) {
            console.error('Error deleteing:', error);
        }
    }
    const handleAssignClick = async (e) => {
        // e.preventDefault();
        try {
            logMessage(searchUid);
            if (searchUid != "") {
                setLoading(true);
                const search_result = await assignUid(searchUid, user_id);
                if (search_result) {
                    // window.location.reload()
                    const data = await getStaffDetail(user_id);
                    setUserId(user_id);
                    setName(data.name)
                    setEmail(data.email);
                    setAssignedMembers(data.assignedUsers);
                   
                    setProfileImageUrl(data.profileImageUrl);
                    setSearchUid("")
                    setSearchEmail("")
                    setLoading(false);
                } else {
                    alert("error in updating user already exists")
                    setLoading(false);
                }

            }
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    }
    const handleSearchClick = async (e) => {
        try {
            if (search != "") {
                setLoading(true);
                const search_result = await searchUser(search);
                if (search_result != null) {
                    logMessage(search_result.uid);
                    setSearchUid(search_result.uid)
                    setSearchEmail(search_result.email)
                    setLoading(false);
                } else {
                    setSearchUid("")
                    setSearchEmail("No user found")
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    }
    const handleImageData = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (
            profileImageUrlInstance === null
        ) {
            // All instances are null, run another function
            handleUpdateData(profileImageUrl);
            // logMessage('handleUpdateData')
        } else {
            // Upload each image instance that is not null
            if (profileImageUrlInstance !== null) {
                await uploadImageInside(profileImageUrlInstance, 1);
            }
            // After uploading images, call handleUpdateData
            logMessage('handleUpdateDatafinal')
            setLoading(false);
            
        }
    };

    const handleUpdateData = async (image_url) => {
        // setLoading(true);
        // e.preventDefault();
        try {
            logMessage(assignedMembers);
            const updated = await updateStaffDetailByAdmin(user_id, name, image_url);
            if (updated) {
                alert("Profile Updated Successfully")
            } else {
                alert("Error in updating profile data")
                setLoading(false);
            }
            setLoading(false);

        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    };

    const handleEditIconClick = (id) => {
        logMessage(id)
        const elementToClick = document.getElementById(id);
    };

    const handleInputImageChange = (index, value) => {
        const file = value.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const imageUrl = event.target.result;
                switch (index) {
                    case 1:
                        setProfileImageUrl(imageUrl);
                        setProfileImageUrlInstance(file);
                        break;
                    default:
                        console.error(`Invalid index: ${index}`);
                }
            };
            fileReader.readAsDataURL(file);
        }
    };
    const uploadImageInside = async (image, index) => {
        if (image) {
            const imageUrl = await uploadImage(image);
            logMessage("imageUrl"+imageUrl);
            await handleUpdateData(imageUrl);
            // switch (index) {
            //     case 1:
                    setProfileImageUrl(imageUrl);
                    setProfileImageUrlInstance(null);
                //     break;
                // default:
                //     console.error(`Invalid index: ${index}`);
            // }
        }
    };

    useEffect(() => {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const userIdParam = searchParams.get('user_id');

        const fetchData = async () => {
            try {
                const data = await getStaffDetail(userIdParam);
                setUserId(userIdParam);
                setName(data.name)
                setEmail(data.email);
                setAssignedMembers(data.assignedUsers);
                logMessage(data.assignedUsers)
                logMessage("hdh"+data.profileImageUrl)
                setProfileImageUrl(data.profileImageUrl);
                // setUsers(data.allUsers)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [location]);

    const handleChatClick = () => {
        navigate(`/chat?user_id=${user_id}`);
    };

    return (
        <div className="inside-page-container">
            <Navbar />
            <Sidebar />
            <div className='body-page-container'>
                {loading && (
                    <div className="loader-container">
                        <Bars color="#36454F" loading={loading} height="40" width="40" />
                    </div>
                )}
                <div className="body-content">
                    <div className="admin-header">
                        <h4>Staff Detail</h4>
                        {/* <span className="start_chat" onClick={handleChatClick}>Start Chat
                          
                        </span> */}
                    </div>
                    <div className="body-content-inside">
                        <form onSubmit={handleImageData}>
                            <div className='card_container'>
                                <div className='left_card'>

                                    <p>General Information</p>
                                    <div className='inside_form_container'>
                                        <div className='input_fields_container'>
                                            <div className="input_row" style={{ width: "110%" }} >
                                                <label htmlFor="uid">UID</label>
                                                <input type="text" id="uid" value={user_id} readOnly={true} />
                                            </div>

                                        </div>
                                        <div className='input_fields_container'>
                                            <div className="input_row">
                                                <label htmlFor="firstName">Name</label>
                                                <input type="text" id="firstName" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="input_row">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" id="email" value={email} readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <p>Assigned Users</p>
                                    <div className='user_fields_container'>

                                        <ol style={{
                                            'margin': 0,
                                            'padding': '12px',
                                            fontSize: '12px'
                                        }} className="inner_left">
                                            {assignedMembers.map(user => (
                                                <li onClick={() => seeProfile(user.id)} style={{ margin: '4px 0px', cursor:"pointer" }} id={user.id}>{user.email}  <span style={{color:'red', cursor:'pointer'}} onClick={() => deleteAssignedUser(user.id)}>&#x2715;</span></li>
                                            ))}
                                        </ol>
                                        <div className="inner_right">
                                            {/* <p>Search user</p> */}
                                            <input style={{ width: '50%', padding: '5px' }} type="text" id="search" onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
                                            <span onClick={() => handleSearchClick() } style={{ marginLeft: '5px', cursor: 'pointer' }}><img src="./images/search.png" style={{ width: '16px' }}></img></span>
                                            {searchUid != "" ? (
                                                <div key={searchUid} className='search_result'>
                                                    {searchEmail}
                                                    <span onClick={() => handleAssignClick()} >Assign</span>
                                                    <span onClick={() => seeProfile(searchUid)} >View</span>
                                                </div>
                                                 
                                            ) : (
                                                <div className='search_result'>
                                                    {searchEmail}
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                    <div className='outerButtonContainer' style={{ marginTop: '10px' }}>
                                        <button type="submit" className="updateUser innerButtonContainer" disabled={loading}>
                                            {loading ? (
                                                <ThreeDots color="#000000" height={30} width={30} />
                                            ) : (
                                                'Update'
                                            )}
                                        </button>
                                    </div>
                                    {/* <button type="submit" className="updateUser" disabled={loading}>
                                        {loading ? (
                                            <ThreeDots color="#000000" height={30} width={30} />
                                        ) : (
                                            'Update'
                                        )}
                                    </button> */}

                                </div>
                                <div className='right_card'>
                                    <div className='image_container'>
                                        <img
                                            alt="profile_img"
                                            className='profile_img'
                                            src={profileImageUrl && profileImageUrl !== "" ? profileImageUrl : "./images/dummyprofile.jpeg"}>
                                        </img>
                                        <div className='edit_icon extraProfile'>
                                            {/* <p style={{cursor:"pointer"}}>Edit</p> */}
                                            <input type="file" onChange={(e) => handleInputImageChange(1, e)} id="profile_image" className="profile_image_class" name="profile_image" accept="image/png, image/jpeg" />
                                            {/* <img className='editicon' 
                                             onClick={handleEditIconClick("profile_image")}
                                             src="./images/yy.png"></img> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default StaffDetail;
