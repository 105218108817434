import React, { useState, useEffect } from 'react';
import imagePaths from '../imagePaths';
import { useNavigate } from "react-router-dom";
import '../services/firebase';
import { getAdmin } from '../services/firebase';
import { ThreeDots } from 'react-loader-spinner';
import { logMessage } from '../utils/helpers';


const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const checkToken = async () => {
            const token = await localStorage.getItem('token');
            logMessage(token);
            if (token && token !== "") {
                navigate("/home");
            }
        };
    
        checkToken();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const isAdmin = await getAdmin(email, password);
            // console.log(isAdmin);
            if (isAdmin) {
                // alert("Login success");
                setLoading(false);
                navigate("/home");
                // Proceed with further actions for logged-in admin
            } else {
                alert("Invalid credentials");
                setLoading(false);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle error
        }
    };
    return (
        <div className='main-container'>
            <div className='page-container'>
                <div className='login-box'>
                    <div className='login-form-container'>
                        <div className='login-form'>
                            <img src={imagePaths.logo} alt="Logo" className="logo" /> {/* Logo image */}
                            <p>Welcome Back</p>
                            <img src={imagePaths.security} alt="Logo" className="small_icons" /> {/* Logo image */}
                            <form onSubmit={handleLogin}>
                                <div className="form-group">
                                    {/* <label htmlFor="email">Email:</label> */}
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <button type="submit" className="sign_in" disabled={loading}>
                                    {loading ? (
                                        <ThreeDots color="#000000" height={30} width={30} />
                                    ) : (
                                        'Sign In'
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
