import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/storage";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { logMessage } from '../utils/helpers';

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };
const firebaseConfig = {
  apiKey: "AIzaSyAbuefdGN8e4H0RyXou8dQgr-or7FN_i4E",
  authDomain: "sivvd-dating-app.firebaseapp.com",
  projectId: "sivvd-dating-app",
  storageBucket: "sivvd-dating-app.appspot.com",
  messagingSenderId: "547619191426",
  appId: "1:547619191426:web:5e427428fea1f8e800d29b"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
// console.log(auth);
export async function getAdmin(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    // console.log(user.email);
    const accessToken = user.accessToken;
    const admin_id = user.uid;
    // console.log("runs first")
    const snapshot = await firebase.firestore().collection('admin').where('email', '==', user.email).get();
    // console.log(snapshot.docs[0].data());
    // console.log("case")
    if (snapshot.empty) {
      // console.log("hdh")
      return false;
    } else {
      const adminData = snapshot.docs[0].data();
      const type = adminData.type;
      await localStorage.setItem('admin_id', admin_id);
      await localStorage.setItem('type', type);
      await localStorage.setItem('token', accessToken);
      // console.log("logintrue")
      return true;
    }
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}


export async function getUserList(limit, startAfter = null, endBefore = null, isApproved = false) {
  try {
    let userList = [];
    let limitStart = 0;
    let query = firebase.firestore()
      .collection('user')
      .where('isApproved', '==', isApproved)
      .orderBy('name');

    query = query.limit(limit);
    if (startAfter) {
      query = query.limit(limit);
      query = query.startAfter(startAfter).limit(limit);
    }
    if (endBefore) {
      query = query.limitToLast(limit);
      query = query.endBefore(endBefore);
    }
    const snapshot = await query.get();
    if (!snapshot.empty) {
      snapshot.forEach(doc => {
        const userData = doc.data();
        limitStart = limitStart + 1;
        if (userData) {
          let singleUser = {
            "sr_no": limitStart,
            "user_id": userData.id,
            "gender": userData.gender,
            "age": userData.age,
            "religion": userData.religion,
            "phoneNumber": userData.phoneNumber || "----",
            "name": userData.name,
            "email": userData.email,
            "profileImageUrl": userData.profileImageUrl || "",
            "country": userData.country,
            "isApproved": userData.isApproved === undefined ? "false" : userData.isApproved.toString(),
          };
          userList.push(singleUser);
        }
      });
      // console.log(userList);
    }
    return { userList, firstVisible: snapshot.docs[0], lastVisible: snapshot.docs[snapshot.docs.length - 1] };

  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

export async function getReportedUserList(limit, startAfter = null, endBefore = null, isApproved = false) {
  try {
    let userList = [];
    let limitStart = 0;
    let query = firebase.firestore()
      .collection('reportedUser')
      .orderBy('createdTime');

    query = query.limit(limit);
    if (startAfter) {
      query = query.limit(limit);
      query = query.startAfter(startAfter).limit(limit);
    }
    if (endBefore) {
      query = query.limitToLast(limit);
      query = query.endBefore(endBefore);
    }
    const snapshot = await query.get();

    if (!snapshot.empty) {
      const promises = snapshot.docs.map(async (doc) => {
        const userData = doc.data();
        const fromSnapshot = await firebase.firestore().collection('user').where('id', '==', userData.from).get();
        const fromSnapshotData = fromSnapshot.docs[0].data(); // Assuming there's only one admin with a matching email
        const toSnapshot = await firebase.firestore().collection('user').where('id', '==', userData.to).get();
        const toSnapshotData = toSnapshot.docs[0].data(); // Assuming there's only one admin with a matching email
        // Assuming adminData contains the name fields you need
        const fromName = fromSnapshotData.email;
        const toName = toSnapshotData.email;

        const date = new Date(userData.createdTime.seconds * 1000).toUTCString();

        limitStart = limitStart + 1;

        let singleUser = {
          "sr_no": limitStart,
          "id": userData.id,
          "from": userData.from,
          "fromName": fromName,
          "toName": toName,
          "to": userData.to,
          "reason": userData.reason || "----",
          "createdTime": date.toString()
        };
        return singleUser;
      });

      userList = await Promise.all(promises);
      logMessage(userList)
    }
    return { userList, firstVisible: snapshot.docs[0], lastVisible: snapshot.docs[snapshot.docs.length - 1] };

  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

export async function getStaffList() {
  try {
    let userList = [];
    let limitStart = 0;

    let query = firebase.firestore().collection('admin').where('type', '==', 'staff');


    const snapshot = await query.get();

    if (!snapshot.empty) {
      const promises = snapshot.docs.map(async (doc) => {
        const userData = doc.data();

        limitStart = limitStart + 1;

        let singleUser = {
          "sr_no": limitStart,
          "user_id": userData.id || "",
          "profileImageUrl": userData.image || "",
          "name": userData.name || "",
          "email": userData.email || "",
        };
        return singleUser;
      });

      userList = await Promise.all(promises);
      logMessage(userList)
    }
    return { userList, firstVisible: snapshot.docs[0], lastVisible: snapshot.docs[snapshot.docs.length - 1] };

  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

export async function getUserDetail(id) {
  // console.log(id);
  try {
    let userData = {
      "user_id": "--",
      "gender": "--",
      "phoneNumber": "--",
      "name": "--",
      "lastName": "--",
      "email": "--",
      "country": "--",
      "age": "--",
      "religion": "--",
      "birthDay": "--",
      "birthMonth": "--",
      "birthYear": "--",
      "maxHeight": "--",
      "minHeight": "--",
      "maxAge": "--",
      "minAge": "--",
      "preference": "--",
      "maritalStatus": "--",
      "matchmakerBio": "",
      "profileImageUrl": "",
      "secondImageUrl": "",
      "thirdImageUrl": "",
      "fourthImageUrl": "",
      "fifthImageUrl": "",
      "sixthImageUrl": "",
      "seventhImageUrl": "",
      "board": "",
      "userBio": "",
      "boardPosition": "",
      "careerFlex": "",
      "children": "",
      "company": "",
      "currentPosition": "",
      "drinking": "",
      "education": "",
      "familyFlex": "",
      "heightInches": "",
      "homes": "",
      "income": "",
      "memberships": "",
      "starSign": "",
      "relocateFlex": "",
      "swipedCount": "",
      "priorPosition": "",
      "politics": "",
      "location1": "",
      "location2": "",
      "location3": "",
      "netWorth": "",
      "smoking": "",
      "sivvdMember": "",
      "isApproved": false, // Set default value as boolean
    };

    const snapshot = await firebase.firestore().collection('user').where('id', '==', id).get();

    if (snapshot.empty) {
      // console.log("No matching documents.");
      return userData;
    } else {
      // console.log("Document data:", snapshot.docs[0].data());
      const userReturned = snapshot.docs[0].data();
      userData = {
        "user_id": userReturned.id || "--",
        "gender": userReturned.gender || "--",
        "phoneNumber": userReturned.phoneNumber ? userReturned.phoneNumber.toString() : "--",
        "name": userReturned.name || "--",
        "lastName": userReturned.lastName || "--",
        "email": userReturned.email || "--",
        "country": userReturned.country || "--",
        "age": userReturned.age || "--",
        "religion": userReturned.religion || "--",
        "birthDay": userReturned.birthDay || "--",
        "birthMonth": userReturned.birthMonth || "--",
        "birthYear": userReturned.birthYear || "--",
        "maxHeight": userReturned.maxHeight || "--",
        "minHeight": userReturned.minHeight || "--",
        "maxAge": userReturned.maxAge || "--",
        "minAge": userReturned.minAge || "--",
        "preference": userReturned.preference || "--",
        "maritalStatus": userReturned.maritalStatus || "--",
        "matchmakerBio": userReturned.matchmakerBio || "",
        "profileImageUrl": userReturned.profileImageUrl || "",
        "secondImageUrl": userReturned.secondImageUrl || "",
        "thirdImageUrl": userReturned.thirdImageUrl || "",
        "fourthImageUrl": userReturned.fourthImageUrl || "",
        "fifthImageUrl": userReturned.fifthImageUrl || "",
        "sixthImageUrl": userReturned.sixthImageUrl || "",
        "seventhImageUrl": userReturned.seventhImageUrl || "",
        "board": userReturned.board || "",
        "userBio": userReturned.userBio || "",
        "boardPosition": userReturned.boardPosition || "",
        "careerFlex": userReturned.careerFlex || "",
        "children": userReturned.children || "",
        "company": userReturned.company || "",
        "currentPosition": userReturned.currentPosition || "",
        "drinking": userReturned.drinking || "",
        "education": userReturned.education || "",
        "familyFlex": userReturned.familyFlex || "",
        "heightInches": userReturned.heightInches || "",
        "homes": userReturned.homes || "",
        "income": userReturned.income || "",
        "memberships": userReturned.memberships || "",
        "starSign": userReturned.starSign || "",
        "relocateFlex": userReturned.relocateFlex || "",
        "swipedCount": userReturned.swipedCount || "",
        "priorPosition": userReturned.priorPosition || "",
        "politics": userReturned.politics || "",
        "location1": userReturned.location1 || "",
        "location2": userReturned.location2 || "",
        "location3": userReturned.location3 || "",
        "netWorth": userReturned.netWorth || "",
        "smoking": userReturned.smoking || "",
        "sivvdMember": userReturned.sivvdMember || "",
        "isApproved": userReturned.isApproved === undefined ? false : userReturned.isApproved,
      };
      // console.log(userData);
      return userData;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {};
  }
}
export async function updateUserDetail(id, makerbio, isApproved, gender, name, country, religion, maxHeight, minHeight, maxAge, minAge, preference, maritalStatus, age, dob, lastName, board, userBio, boardPosition, careerFlex, children, company, currentPosition, drinking, education, familyFlex, heightInches, homes, income, memberships, starSign, relocateFlex, swipedCount, priorPosition, politics, location1, location2, location3, netWorth, smoking, sivvdMember) {
  try {
    logMessage("lineno178userDetail" + board);
    const [year, month, day] = dob.split('-').map(Number);
    const formattedMonth = +month;
    const formattedDay = +day;
    logMessage(formattedMonth)
    logMessage(formattedDay)
    logMessage(year)
    await firebase.firestore().collection('user').doc(id).update({
      matchmakerBio: makerbio,
      isApproved: isApproved,
      gender: gender,
      name: name,
      country: country,
      religion: religion,
      maxHeight: maxHeight,
      minHeight: minHeight,
      maxAge: maxAge,
      minAge: minAge,
      preference: preference,
      maritalStatus: maritalStatus,
      age: age,
      lastName: lastName,
      birthDay: formattedDay,
      birthMonth: formattedMonth,
      birthYear: year,
      // profileImageUrl: profileImageUrl,
      // secondImageUrl: secondImageUrl,
      // thirdImageUrl: thirdImageUrl,
      // fourthImageUrl: fourthImageUrl,
      // fifthImageUrl: fifthImageUrl,
      // sixthImageUrl: sixthImageUrl,
      // seventhImageUrl: seventhImageUrl,
      board: board,
      userBio: userBio,
      boardPosition: boardPosition,
      careerFlex: careerFlex,
      children: children,
      company: company,
      currentPosition: currentPosition,
      drinking: drinking,
      education: education,
      familyFlex: familyFlex,
      heightInches: heightInches,
      homes: homes,
      income: income,
      memberships: memberships,
      starSign: starSign,
      relocateFlex: relocateFlex,
      swipedCount: swipedCount ? parseInt(swipedCount, 10) : 0,
      priorPosition: priorPosition,
      politics: politics,
      location1: location1,
      location2: location2,
      location3: location3,
      netWorth: netWorth,
      smoking: smoking,
      sivvdMember: sivvdMember
    });

    const userSnapshot = await firebase.firestore().collection('user').doc(id).get();
    const userData = userSnapshot.data();
    logMessage(userData.name)

    const chatQuerySnapshot = await firebase.firestore().collection('chat')
      .where('user1.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot.docs) {
      try {
        // Update user1 document
        logMessage("user1")
        // if (id == "k2juoSjIBTTh70EZq8onQSmmLPu1") {
        logMessage("user1 inside check")
        await doc.ref.update({ user1: userData });
        // }
      } catch (error) {
        console.error('Error updating user1 document:', error);
      }
    }

    const chatQuerySnapshot2 = await firebase.firestore().collection('chat')
      .where('user2.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot2.docs) {
      try {
        // Update user2 document
        logMessage(doc.id)
        // if (id == "85d1bGzrRlhNhEhSDJgLucHp9BU2") {
        logMessage("user2 inside check")
        logMessage(userData.name)
        await doc.ref.update({ user2: userData });
        // }
      } catch (error) {
        console.error('Error updating user2 document:', error);
      }
    }

    return true;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}


export async function updateStaffDetailByAdmin(id, name, profileImage) {
  try {

    await firebase.firestore().collection('admin').doc(id).update({
      name: name,
      image: profileImage,
      // assignedUsers: assignedMembers,
    });

    const userSnapshot = await firebase.firestore().collection('admin').doc(id).get();
    const userData = userSnapshot.data();
    logMessage(userData.name)

    const chatQuerySnapshot = await firebase.firestore().collection('chat')
      .where('user2.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot.docs) {
      try {
        // Update user1 document
        logMessage("user1")
        // if (id == "k2juoSjIBTTh70EZq8onQSmmLPu1") {
        logMessage("user1 inside check")
        const updatedUserData = {
          name: userData.name,
          profileImageUrl: userData.image
        };
        await doc.ref.update({ user1: updatedUserData });
        // }
      } catch (error) {
        console.error('Error updating user2 document:', error);
      }
    }

    return true;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}
export async function createNewStaff(email, password, name, profileImage) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    logMessage(userCredential)
    const uid = userCredential.user.uid;

    const userRef = firebase.firestore().collection('admin').doc(uid);
    await userRef.set({
      email: email,
      type: 'staff',
      id: uid,
      image: profileImage,
      createdTime: firebase.firestore.FieldValue.serverTimestamp(),
      // assignedUsers: assignedMembers,
      name: name,
    });

    return true;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}
export async function retrieveChat(user_id) {
  try {
    const admin_id = await localStorage.getItem('admin_id');
    logMessage("userid503");
    logMessage(user_id);
    const chatQuerySnapshot = await firebase.firestore().collection('chat').where(`user1.id`, '==', user_id).where(`user2.id`, '==', admin_id).get();
    if (!chatQuerySnapshot.empty) {

      const chatId = chatQuerySnapshot.docs[0].id;
      const sivvdMember = chatQuerySnapshot.docs[0].data().user1.sivvdMember;
      const name = chatQuerySnapshot.docs[0].data().user1.name;
      const image = chatQuerySnapshot.docs[0].data().user1.profileImageUrl;
      // Retrieve messages for the chat
      const messagesQuerySnapshot = await firebase.firestore().collection('chat').doc(chatId).collection('messages').orderBy('timestamp', 'asc').get();

      // Extract messages from the query snapshot
      const messages = messagesQuerySnapshot.docs.map(doc => doc.data());
      logMessage("userid");
      return { chatId, name: name, sivvdMember: sivvdMember, image: image, messages };
    } else {
      // const user2 = {
      //   id: admin_id,
      //   email: "admin@napworks.in",
      //   name: "Admin",
      //   profileImageUrl: "https://firebasestorage.googleapis.com/v0/b/sivvd-dating-app.appspot.com/o/logo.png?alt=media&token=1df5d402-cff6-4358-8b63-f33c887a3c42",
      //   lastName: "",
      //   country: "",
      //   starSign: "",
      //   moonSign: "",
      //   maritalStatus: "",
      //   children: "",
      //   drinking: "",
      //   smoking: "",
      //   religion: "",
      //   politics: "",
      //   income: "",
      //   netWorth: "",
      //   currentPosition: "",
      //   priorPosition: "",
      //   boardPosition: "",
      //   careerFlex: "",
      //   familyFlex: "",
      //   relocateFlex: "",
      //   location1: "",
      //   location2: "",
      //   location3: "",
      //   gender: "",
      //   preference: "",
      //   sivvdMember: "",
      //   interests: [],
      //   mustHave: [],
      //   niceToHave: [],
      //   swipesLeft: [],
      //   swipesRight: [],
      //   matches: []
      // };
      const userSnapshot = await firebase.firestore().collection('user').where('id', '==', user_id).get();
      if (!userSnapshot.empty) {
        // console.log("No matching documents.");

        // console.log("Document data:", snapshot.docs[0].data());
        const userData = userSnapshot.docs[0].data();

        const name = userData.name;
        const sivvdMember = userData.sivvdMember;
        const image = userData.profileImageUrl;
        return { chatId: "", name: name, sivvdMember: sivvdMember, image: image, messages: [] };
      } else {
        return null;
      }
      // const dataCreatedRef = await firebase.firestore().collection('chat');
      // const autoId = dataCreatedRef.doc().id;

      // const chatData = {
      //   createdTime: firebase.firestore.FieldValue.serverTimestamp(),
      //   id: autoId,
      //   type: 'support',
      //   user1: {
      //     ...userData
      //   },
      //   user2: {
      //     ...user2

      //   }
      // };
      // const newChatDocRef = await dataCreatedRef.doc(autoId).set(chatData);


    }
  }
  catch (error) {
    console.error('Error fetching admin data:', error);
    return null;
  }
}

export const getMessageCollectionRef = (chatId) => {
  logMessage(chatId);
  return firebase.firestore().collection('chat').doc(chatId).collection('messages').orderBy('timestamp', 'asc');
};

export const getConversationListNew = async () => {
  // logMessage(chatId);
  const admin_id = await localStorage.getItem('admin_id');
  const chatQuerySnapshot = await firebase.firestore().collection('chat').where('type', '==', "support").where('user2.id', '==', admin_id).get();
  return chatQuerySnapshot.docs;
}

export const sendMessage = async (chatId, message, type) => {
  try {
    logMessage(message)
    const admin_id = await localStorage.getItem('admin_id');
    // Get a reference to the messages collection for the specified chat ID
    const messagesRef = firebase.firestore().collection('chat').doc(chatId).collection('messages');
    // const timestampInSeconds = Math.floor(Date.now() / 1000);
    // logMessage("jhj"+ messagesRef.doc().id);
    // Create a new message entry in the messages collection
    const autoId = messagesRef.doc().id;
    const newMessageRef = await messagesRef.doc(autoId).set({
      id: autoId,
      text: message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      // customTimeStamp: timestampInSeconds,
      status: "unread",
      sentBy: admin_id,
      type: type
    });

    // await newMessageRef.update({ id: newMessageRef.id });
    return true;

  } catch (error) {
    return false;
    console.error('Error sending message:', error);
  }
};
export const sendFirstMessage = async (user_id, message, type) => {
  try {
    logMessage(message)
    const admin_id = await localStorage.getItem('admin_id');
    const snapshot = await firebase.firestore().collection('admin').where('id', '==', admin_id).get();
    let email = "";
    let name = "";
    let profileImageUrl = "";
    if (snapshot.empty) {
      return false;
    } else {
      const adminData = snapshot.docs[0].data();
      email = adminData.email;
      name = adminData.name;
      profileImageUrl = adminData.image;
    }
    const user2 = {
      id: admin_id,
      email: email,
      name: name,
      profileImageUrl: profileImageUrl,
      lastName: "",
      country: "",
      starSign: "",
      moonSign: "",
      maritalStatus: "",
      children: "",
      drinking: "",
      smoking: "",
      religion: "",
      politics: "",
      income: "",
      netWorth: "",
      currentPosition: "",
      priorPosition: "",
      boardPosition: "",
      careerFlex: "",
      familyFlex: "",
      relocateFlex: "",
      location1: "",
      location2: "",
      location3: "",
      gender: "",
      preference: "",
      sivvdMember: "",
      interests: [],
      mustHave: [],
      niceToHave: [],
      swipesLeft: [],
      swipesRight: [],
      matches: []
    };
    const userSnapshot = await firebase.firestore().collection('user').doc(user_id).get();
    const userData = userSnapshot.data();
    const dataCreatedRef = await firebase.firestore().collection('chat');
    const chatId = dataCreatedRef.doc().id;

    const chatData = {
      createdTime: firebase.firestore.FieldValue.serverTimestamp(),
      id: chatId,
      type: 'support',
      user1: {
        ...userData
      },
      user2: {
        ...user2

      }
    };

    const newChatDocRef = await dataCreatedRef.doc(chatId).set(chatData);

    const messagesRef = firebase.firestore().collection('chat').doc(chatId).collection('messages');

    const autoId = messagesRef.doc().id;
    const newMessageRef = await messagesRef.doc(autoId).set({
      id: autoId,
      text: message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      // customTimeStamp: timestampInSeconds,
      status: "unread",
      sentBy: admin_id,
      type: type
    });

    // await newMessageRef.update({ id: newMessageRef.id });
    return chatId;

  } catch (error) {
    return false;
    console.error('Error sending message:', error);
  }
};
export const uploadImage = async (image) => {
  try {
    logMessage("goin here");
    const timestamp = Date.now();
    const imageName = `${timestamp}_${image.name}`;
    logMessage("goin here 2");
    const storageRef = firebase.storage().ref().child("chat_images");
    logMessage("goin here 2.2");
    const imageRef = storageRef.child(imageName);
    logMessage("goin here 3");
    await imageRef.put(image);
    logMessage("goin here 4");
    const imageUrl = await imageRef.getDownloadURL();
    logMessage(imageUrl);
    return imageUrl;
  } catch (error) {
    return "";
  }
};

export const getConversationList = async () => {
  try {
    const admin_id = await localStorage.getItem('admin_id');
    // logMessage(admin_id);
    const conversations = [];

    const chatQuerySnapshot = await firebase.firestore().collection('chat').where('type', '==', "support").where('user2.id', '==', admin_id).get();

    for (const chatDoc of chatQuerySnapshot.docs) {
      const chatId = chatDoc.id;
      const userData = chatDoc.data().user1;
      const name = userData.name;
      const email = userData.email;
      const sivvdMember = userData.sivvdMember;
      const image = userData.profileImageUrl;
      logMessage(image)
      const userId = userData.id;
      const messageQuerySnapshot = await firebase.firestore().collection('chat').doc(chatId).collection('messages')
        .orderBy('timestamp', 'desc').limit(1).get();

      let lastMessage = null;
      if (!messageQuerySnapshot.empty) {
        const lastMessageDoc = messageQuerySnapshot.docs[0];
        const lastMessageData = lastMessageDoc.data();
        lastMessage = {
          text: lastMessageData.text,
          type: lastMessageData.type,
          timestamp: lastMessageData.timestamp,
        };
        conversations.push({
          chatId: chatId,
          name: name,
          sivvdMember: sivvdMember,
          userId: userId,
          image: image,
          email: email,
          lastMessage: lastMessage
        });
      }
    }
    conversations.sort((a, b) => {
      if (!a.lastMessage) return 1; // Put conversations without messages at the end
      if (!b.lastMessage) return -1; // Put conversations without messages at the end
      return b.lastMessage.timestamp - a.lastMessage.timestamp;
    });
    return conversations;
  } catch (error) {
    console.error('Error fetching conversation list:', error);
    throw error;
  }
};


export async function getUserListSearch(isApproved = false, searchParam = null) {
  try {
    let userList = [];
    let limitStart = 0;

    let query = firebase.firestore().collection('user').where('isApproved', '==', isApproved);

    if (searchParam) {
      const emailEndSearchParam = searchParam.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

      let emailQuery = query
        .orderBy('email')
        .startAt(searchParam)
        .endBefore(emailEndSearchParam);

      let uidQuery = query.where('id', '==', searchParam);

      const [emailSnapshot, uidSnapshot] = await Promise.all([emailQuery.get(), uidQuery.get()]);

      const mergedDocs = [...emailSnapshot.docs, ...uidSnapshot.docs];
      const uniqueDocs = Array.from(new Set(mergedDocs.map(doc => doc.id)))
        .map(id => mergedDocs.find(doc => doc.id === id));

      uniqueDocs.forEach(doc => {
        const userData = doc.data();
        limitStart += 1;
        if (userData) {
          let singleUser = {
            "sr_no": limitStart,
            "user_id": userData.id,
            "gender": userData.gender,
            "age": userData.age,
            "religion": userData.religion,
            "phoneNumber": userData.phoneNumber || "----",
            "name": userData.name,
            "email": userData.email,
            "profileImageUrl": userData.profileImageUrl || "",
            "country": userData.country,
            "isApproved": userData.isApproved === undefined ? "false" : userData.isApproved.toString(),
          };
          userList.push(singleUser);
        }
      });
    } else {
      // If no searchParam, fetch all users matching the isApproved status
      const snapshot = await query.get();
      snapshot.forEach(doc => {
        const userData = doc.data();
        limitStart += 1;
        if (userData) {
          let singleUser = {
            "sr_no": limitStart,
            "user_id": userData.id,
            "gender": userData.gender,
            "age": userData.age,
            "religion": userData.religion,
            "phoneNumber": userData.phoneNumber || "----",
            "name": userData.name,
            "email": userData.email,
            "profileImageUrl": userData.profileImageUrl || "",
            "country": userData.country,
            "isApproved": userData.isApproved === undefined ? "false" : userData.isApproved.toString(),
          };
          userList.push(singleUser);
        }
      });
    }

    return { userList, firstVisible: null, lastVisible: null };

  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

export async function getStaffDetail(id) {
  // console.log(id);
  try {
    let userData = {
      "user_id": "--",
      "name": "--",
      "profileImageUrl": "--",
      "email": "--",
      "assignedUsers": [],
      "allUsers": []
    };
    const snapshot = await firebase.firestore().collection('admin').where('id', '==', id).get();

    if (snapshot.empty) {
      // console.log("No matching documents.");
      return userData;
    } else {

      const userReturned = snapshot.docs[0].data();
      const assignedUserIds = userReturned.assignedUsers || [];
      logMessage(assignedUserIds);
      const assignedUsers = [];
      for (const userId of assignedUserIds) {
        const userDoc = await firebase.firestore().collection('user').where('id', '==', userId).get();
        if (!userDoc.empty) {
          const userData = userDoc.docs[0].data();
          assignedUsers.push({
            id: userData.id,
            email: userData.email || "--",
            name: userData.name || "--"
          });
        }
      }

      userData = {
        "user_id": userReturned.id || "--",
        "name": userReturned.name || "--",
        "profileImageUrl": userReturned.image || "",
        "email": userReturned.email || "--",
        "assignedUsers": assignedUsers || [],
        "allUsers": []
      };
      logMessage(userData);
      // console.log(userData);
      return userData;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {};
  }
}

export async function getAllUsersList() {
  try {
    let allUsersList = [];
    const snapshotUsers = await firebase.firestore().collection('user').get();
    snapshotUsers.forEach(doc => {
      const data = doc.data();
      const user = {
        email: data.email || "--",
        uid: doc.id || "--"
      };
      allUsersList.push(user);
    });
    return allUsersList;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return [];
  }

};

export async function updateStaffDetail(id, name, profileImage) {
  try {
    logMessage(id)
    await firebase.firestore().collection('admin').doc(id).update({
      name: name,
      image: profileImage
    });

    const userSnapshot = await firebase.firestore().collection('admin').doc(id).get();
    const userData = userSnapshot.data();
    logMessage(userData.name)

    const chatQuerySnapshot = await firebase.firestore().collection('chat')
      .where('user2.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot.docs) {
      try {
        // Update user1 documen
        // if (id == "k2juoSjIBTTh70EZq8onQSmmLPu1") {
        // const updatedUserData = {
        //   name: userData.name,
        //   profileImageUrl: userData.image
        // };
        const updatedUserData = {
          id: id,
          email: userData.email,
          name: userData.name,
          profileImageUrl: userData.image,
          lastName: "",
          country: "",
          starSign: "",
          moonSign: "",
          maritalStatus: "",
          children: "",
          drinking: "",
          smoking: "",
          religion: "",
          politics: "",
          income: "",
          netWorth: "",
          currentPosition: "",
          priorPosition: "",
          boardPosition: "",
          careerFlex: "",
          familyFlex: "",
          relocateFlex: "",
          location1: "",
          location2: "",
          location3: "",
          gender: "",
          preference: "",
          sivvdMember: "",
          interests: [],
          mustHave: [],
          niceToHave: [],
          swipesLeft: [],
          swipesRight: [],
          matches: []
        };
        await doc.ref.update({ user2: updatedUserData });
        // }
      } catch (error) {
        console.error('Error updating user2 document:', error);
      }
    }

    return true;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}

export const getAssignedMemberList = async (id) => {
  try {
    let limitStart = 0;
    const userSnapshot = await firebase.firestore().collection('admin').doc(id).get();
    const userData = userSnapshot.data();
    if (!userData) {
      logMessage("inside")
      return [];
    }

    const assignedUserIds = userData.assignedUsers || [];

    if (assignedUserIds.length === 0) {
      return [];
    }
    const assignedUsers = [];
    for (const userId of assignedUserIds) {

      const userDoc = await firebase.firestore().collection('user').where('id', '==', userId).get();
      if (!userDoc.empty) {

        const userData = userDoc.docs[0].data();
        // let chatId = "";
        // let lastMessage = null;
        // const chatSnapshot = await firebase.firestore().collection('chat')
        //   .where('user1.id', '==', userData.id)
        //   .where('user2.id', '==', id)
        //   .get();
        // if (!chatSnapshot.empty) {

          // const chatData = chatSnapshot.docs[0].data();
          // chatId = chatData.id;
          // const messageQuerySnapshot = await firebase.firestore().collection('chat').doc(chatId).collection('messages')
          //   .orderBy('timestamp', 'desc').limit(1).get();
          // if (!messageQuerySnapshot.empty) {
          //   const lastMessageDoc = messageQuerySnapshot.docs[0];
          //   const lastMessageData = lastMessageDoc.data();
          //   lastMessage = {
          //     text: lastMessageData.text,
          //     type: lastMessageData.type,
          //     timestamp: lastMessageData.timestamp,
          //   };
          // }
        // }
        limitStart += 1;
        assignedUsers.push({
          sr_no: limitStart,
          userId: userData.id,
          sivvdMember: userData.sivvdMember || "",
          email: userData.email || "--",
          name: userData.name || "--",
          image: userData.profileImageUrl || "",
          // chatId: userData.chatId,
          // lastMessage: userData.lastMessage,
        });

      }
    }
    logMessage(assignedUsers);
    return assignedUsers;
  }
  catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}

export async function searchUser(search) {
  try {

    const userDoc = await firebase.firestore().collection('user').where('id', '==', search).get();
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      return {
        email: userData.email,
        uid: userDoc.id
      };
    }
    const emailQuery = await firebase.firestore().collection('user').where('email', '==', search).get();
    if (!emailQuery.empty) {
      const userData = emailQuery.docs[0].data();
      return {
        email: userData.email,
        uid: userData.id
      };
    }

    return null;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return null;
  }
}
export async function searchUserAssigned(search, assignedMembers) {
  try {

    const userDoc = await firebase.firestore().collection('user').where('id', '==', search).get();
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      const matchedMember = assignedMembers.find(member => member.userId === userData.id);
      if (matchedMember) {  
        return [{
          sr_no: 1,
          userId: userData.id,
          sivvdMember: userData.sivvdMember || "",
          email: userData.email || "--",
          name: userData.name || "--",
          image: userData.profileImageUrl || ""
        }];
      }
    }
    const emailQuery = await firebase.firestore().collection('user').where('email', '==', search).get();
    if (!emailQuery.empty) {
     
      const userData = emailQuery.docs[0].data();
      const matchedMember = assignedMembers.find(member => member.userId === userData.id);
      if (matchedMember) {
        return [{
          sr_no: 1,
          userId: userData.id,
          sivvdMember: userData.sivvdMember || "",
          email: userData.email || "--",
          name: userData.name || "--",
          image: userData.profileImageUrl || ""
        }];
      }
    }

    return [];
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return [];
  }
}

export async function assignUid(uid, staff_id) {
  try {
    logMessage(uid);
    const userDoc = await firebase.firestore().collection('admin').where('id', '==', staff_id).get();
    if (!userDoc.empty) {
      const userDocRef = userDoc.docs[0].ref;
      const userData = userDoc.docs[0].data();

      if (userData.assignedUsers && Array.isArray(userData.assignedUsers)) {
        if (userData.assignedUsers.includes(uid)) {
          // alert("user already exists in assigned user list")
          return false;
        } else {
          await userDocRef.update({
            assignedUsers: firebase.firestore.FieldValue.arrayUnion(uid)
          });
          return true;
        }
      } else {
        await userDocRef.update({
          assignedUsers: [uid]
        });
        return true;
      }
    } else {
      return false;
    }
  }
  catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}

export async function deleteAssignedUserDatabase(uid, staff_id) {
  try {
    const userDoc = await firebase.firestore().collection('admin').where('id', '==', staff_id).get();
    if (!userDoc.empty) {
      const userDocRef = userDoc.docs[0].ref;
      const userData = userDoc.docs[0].data();

      if (userData.assignedUsers && Array.isArray(userData.assignedUsers)) {
        if (userData.assignedUsers.includes(uid)) {
          // Remove uid from the assignedUsers array
          await userDocRef.update({
            assignedUsers: firebase.firestore.FieldValue.arrayRemove(uid)
          });
          return true;
        } else {
          // uid is not in the assignedUsers array
          return false;
        }
      } else {
        // assignedUsers array does not exist, nothing to remove
        return false;
      }
    } else {
      // Staff ID not found
      return false;
    }
  } catch (error) {
    console.error('Error removing uid from assignments:', error);
    return false;
  }
}
export async function getReportedSearchUserList(searchParam) {
  try {
    let userList = [];
    let limitStart = 0;

    // Define queries to search for matches in `from`, `to`, `from_email`, and `to_email`
    const fromQuery = firebase.firestore().collection('reportedUser').where('from', '==', searchParam).get();
    const toQuery = firebase.firestore().collection('reportedUser').where('to', '==', searchParam).get();
    const fromEmailQuery = firebase.firestore().collection('reportedUser').where('from_email', '==', searchParam).get();
    const toEmailQuery = firebase.firestore().collection('reportedUser').where('to_email', '==', searchParam).get();

    // Execute all queries in parallel
    const [fromSnapshot, toSnapshot, fromEmailSnapshot, toEmailSnapshot] = await Promise.all([fromQuery, toQuery, fromEmailQuery, toEmailQuery]);

    // Combine all query results into one array
    const combinedDocs = [...fromSnapshot.docs, ...toSnapshot.docs, ...fromEmailSnapshot.docs, ...toEmailSnapshot.docs];
    logMessage(combinedDocs);
    // Remove duplicates
    const uniqueDocs = Array.from(new Set(combinedDocs.map(doc => doc.id)))
      .map(id => combinedDocs.find(doc => doc.id === id));

    // Fetch additional details for each reported user
    const promises = uniqueDocs.map(async (doc) => {
      const userData = doc.data();

      const fromSnapshot = await firebase.firestore().collection('user').doc(userData.from).get();
      const toSnapshot = await firebase.firestore().collection('user').doc(userData.to).get();

      const fromName = fromSnapshot.exists ? fromSnapshot.data().email : 'Unknown';
      const toName = toSnapshot.exists ? toSnapshot.data().email : 'Unknown';

      const date = new Date(userData.createdTime.seconds * 1000).toUTCString();

      limitStart = limitStart + 1;

      return {
        "sr_no": limitStart,
        "id": userData.id,
        "from": userData.from,
        "fromName": fromName,
        "toName": toName,
        "to": userData.to,
        "reason": userData.reason || "----",
        "createdTime": date.toString()
      };
    });

    userList = await Promise.all(promises);

    return { userList, firstVisible: uniqueDocs[0], lastVisible: uniqueDocs[uniqueDocs.length - 1] };

  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
}


export async function updateImageOnly(id,imageurl,index) {
  try {
    const imageUrlKey = getImageUrlKey(index);
    const updateObject = { [imageUrlKey]: imageurl };

     await firebase.firestore().collection('user').doc(id).update(updateObject);


    const userSnapshot = await firebase.firestore().collection('user').doc(id).get();
    const userData = userSnapshot.data();
    logMessage(userData.name)

    const chatQuerySnapshot = await firebase.firestore().collection('chat')
      .where('user1.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot.docs) {
      try {
        // Update user1 document
        logMessage("user1")
        // if (id == "k2juoSjIBTTh70EZq8onQSmmLPu1") {
        logMessage("user1 inside check")
        await doc.ref.update({ user1: userData });
        // }
      } catch (error) {
        console.error('Error updating user1 document:', error);
      }
    }

    const chatQuerySnapshot2 = await firebase.firestore().collection('chat')
      .where('user2.id', '==', id)
      .get();

    for (const doc of chatQuerySnapshot2.docs) {
      try {
        // Update user2 document
        logMessage(doc.id)
        // if (id == "85d1bGzrRlhNhEhSDJgLucHp9BU2") {
        logMessage("user2 inside check")
        logMessage(userData.name)
        await doc.ref.update({ user2: userData });
        // }
      } catch (error) {
        console.error('Error updating user2 document:', error);
      }
    }

    return true;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    return false;
  }
}

function getImageUrlKey(index) {
  switch (index) {
    case 1:
      return 'profileImageUrl';
    case 2:
      return 'secondImageUrl';
    case 3:
      return 'thirdImageUrl';
    case 4:
      return 'fourthImageUrl';
    case 5:
      return 'fifthImageUrl';
    case 6:
      return 'sixthImageUrl';
    case 7:
      return 'seventhImageUrl';
    default:
      throw new Error('Invalid index');
  }
}